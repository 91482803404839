<template>
	<div class="blacklist">
		<div class="head-top">
			<van-icon name="warning" size="80px" color="#FF6969" />
		</div>
		<div class="head-title">
			账号被禁用
		</div>
		<div class="head-text">
			如有疑问请联系管理员
		</div>
	</div>
</template>

<script>
export default {
	name: "Blacklist"
}
</script>

<style lang="less" scoped>
.blacklist {
	background-color: #fff;
	box-sizing: border-box;
	text-align: center;

	.head-top {
		padding-top: 100px;
	}

	.head-title {
		color: #333;
		font-size: 18px;
		font-weight: bold;
		padding: 30px 0 15px;
	}

	.head-text {
		color: #999;
		font-size: 14px;
	}
}
</style>